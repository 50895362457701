/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one components. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "webapp";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

.ion-color-purple {
  --ion-color-base: #893bd6;
  --ion-color-base-rgb: 137, 59, 214;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #730ed8;
  --ion-color-tint: #a06ad6;
}

.ion-color-action-btn {
  --ion-color-base: #202020;
  --ion-color-contrast: #ffffff;
  --ion-color-shade: #202020;
  --ion-color-tint: #202020;
}

.ion-color-submit-btn {
  --ion-color-base: #38b6ff;
  --ion-color-contrast: #ffffff;
  --ion-color-shade: #38b6ff;
  --ion-color-tint: #38b6ff;
}

* {
  margin: 0;
  padding: 0;
  font-size: 12;
}

body {
  font-family: "Lato", sans-serif;
}

ul,
li {
  list-style: none;
}
.f8 {
  font-size: 8px !important;
}
.f9 {
  font-size: 9px !important;
}
.f10 {
  font-size: 10px !important;
}
.small {
  font-size: 14px !important;
}
.smaller {
  font-size: 12px !important;
}
.medium {
  font-size: 20px !important;
  font-weight: 500;
}
.larger {
  font-size: larger !important;
}
.large {
  font-size: x-large !important;
}
.x-large {
  font-size: xx-large !important;
  font-weight: 600;
}
.xx-large {
  font-size: 36px !important;
  font-weight: 600;
}

.tb {
  font-weight: 800;
}

a {
  color: #0a48b7;
}

ion-content {
  --background: var(--ion-color-primary);

  &.bg-white {
    --background: var(--ion-color-light);
  }
}

.df {
  display: flex !important;
}
.tac {
  text-align: center;
}

.ac {
  align-items: center !important;
}

.fdc {
  flex-direction: column !important;
}

.afe {
  align-items: flex-end !important;
}
.afs {
  align-items: flex-start !important;
}

.jsb {
  justify-content: space-between;
}

.jse {
  justify-content: space-evenly;
}

.jc {
  justify-content: center !important;
}
.jfe {
  justify-content: flex-end !important;
}
.jfs {
  justify-content: flex-start !important;
}

.left-auto {
  margin-left: auto !important;
}
.cap-case {
  text-transform: capitalize !important;
}
.relative {
  position: relative !important;
}

.bdr-top {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.radius16 {
  border-radius: 16px !important;
}

.af-none {
  position: relative;

  &:after,
  &:before {
    content: "";
    background: transparent none !important;
  }
}

ion-textarea,
ion-input {
  font-size: 14px !important;
}
ion-textarea,
ion-input,
ion-text,
ion-button,
ion-searchbar {
  font-family: "Lato", sans-serif !important;
}

.course-logo {
  width: 98px;
  height: 42px;
  margin-top: 10px !important;
}

.transparent {
  background: transparent !important;
  --background: transparent !important;
}

.no-shadow {
  box-shadow: none;
  --box-shadow: none;
}

.heading {
  h3 {
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
    color: var(--ion-color-tertiary);
  }
}

.ion-icon-container {
  ion-icon {
    pointer-events: none;
    font-size: 20px;
  }
}

.fixInfo {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 99;
  & .info-icon {
    height: 24px;
  }
  &.right {
    right: 16px;
    left: inherit;
  }
}
.back-arrow {
  left: 65px;
}

.name {
  font-size: 13px;
  padding: 0px 10px;
  line-height: 20px;
}

.nav-name {
  font-size: 16px;
  padding: 0px 10px;
  line-height: 28px;
  opacity: 0.6;
}
.cursor-pointer {
  cursor: pointer;
}
.sm-btn {
  font-size: 11px;
  text-transform: capitalize;
  height: 34px;
  margin-top: 9px;
}
.med-btn {
  font-size: 14px;
  text-transform: capitalize;
  height: 34px;
  margin-top: 9px;
}
.img-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .img-group-btn {
    padding: 0;
    height: 20px;
    width: 20px;
    align-self: start;
  }

  ion-img {
    object-fit: contain;
    width: 26px;
    height: 26px;
  }
  .menu-img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }
}

.searchBar {
  input {
    border-radius: 6px !important;
    padding-left: 40px !important;
    padding-right: 35px !important;
    height: 35px !important;
    font-size: 14px !important;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 10px !important;
    top: 8px !important;
  }
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;

    li {
      display: flex;
      align-items: center;
      padding: 5px 0px;
      cursor: pointer;
      p,
      a {
        color: white;
        text-decoration: none;
      }

      ion-icon {
        margin-right: 5px;
        font-size: 16px;
      }
    }
  }
}

.lg-name {
  font-size: 18px;
  line-height: 22px;
  padding-left: 10px;
}

.img-thumbnail {
  border: 2px solid rgba(255, 255, 255, 0.32);
  width: 100%;
  height: 110px;
  margin-top: 4px;
  background: var(--ion-color-light);

  ion-img {
    width: 100%;
    height: 100%;
  }
}
.rate {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 600;
  }
}

.quiz {
  .title {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
  }
  .numberQue {
    font-size: 14px;
    text-align: center;
  }
  .answer {
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
  }
}

.radio-btn {
  display: flex;
  justify-content: space-evenly;

  .circle {
    width: 23px;
    min-width: 23px;
    height: 23px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-primary);
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;

    &.yes {
      background: var(--ion-color-success);
      color: var(--ion-color-light);
    }
    &.no {
      background: var(--ion-color-danger);
    }
  }
}

.cs-pargraph {
  p {
    font-size: 14px;
    color: var(--ion-color-light);
    line-height: 22px;
    text-align: left;
    margin-bottom: 16px;
  }
}

.cs-heading {
  margin: 0 0 16px 0;
  color: white;
  text-align: center;
  .cs-heading-title {
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 0 0 8px 0;
    font-weight: 100;
  }
}

.ionic-selectable-modal {
  ion-content {
    --background: var(--ion-color-light) !important;
  }

  ion-button {
    color: var(--ion-color-danger);
  }

  .ionic-selectable-group {
    ion-label {
      margin-left: 10px;
      font-size: 14px !important;
    }
  }
}

ion-button {
  ion-spinner {
    display: none;
  }
  &.loading {
    ion-label {
      font-size: 0;
    }
    ion-spinner {
      display: block;
    }
  }
}

.custom-modal {
  background: rgba(40, 40, 40, 0.6) !important;
  --background: rgba(40, 40, 40, 0.6) !important;
  padding-top: 64px;
}

// Form Input Block

.input-block {
  border: 2px solid rgba(143, 116, 84, 0.4);
  margin: 12px 10px 10px;
  border-radius: 5px;
  color: var(--ion-color-primary);

  ion-label {
    font-size: 4px;
  }
}

.selectable {
  ionic-selectable {
    height: 41px;
    background: var(--ion-color-light);
    color: var(--ion-color-primary);
    padding: 0px 10px;
    font-size: 14px;
  }
}

.bg-white {
  background-color: var(--ion-color-light);
}

.collapse {
  margin-bottom: 10px;
  margin: 0px -16px;

  .collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px 0px 16px;
    min-height: 30px;
    position: relative;
    border-bottom: 1px solid var(--ion-color-medium);;

    h2 {
      margin: 0px;
      font-size: 16px;
      color: var(--ion-color-light);
    }

    span {
      font-size: 12px;
      color: var(--ion-color-light);
    }

    &:after {
      content: "";
      position: absolute;
      top: 10px;
      right: 20px;
      background: url("./assets/images/caret_down.png") no-repeat;
      width: 20px;
      height: 20px;
      background-size: 100%;
    }
  }
  .collapse-body {
    background-color: var(--ion-color-dark);
    padding: 2px;
    display: none;

    .body-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;

      ion-text {
        font-size: 14px;
        color: #cac9c9;
        text-decoration: underline;
      }
      ion-button {
        text-transform: capitalize;
      }
    }

    .right-btn-item {
      display: flex;
      align-items: center;

      ion-fab-button {
        width: 30px;
        height: 30px;
        --background: transparent;
        --box-shadow: none;
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &.active {
    .collapse-header {
      border-bottom: 0px;

      &:after {
        content: "";
        background: url("./assets/images/caret_down.png") no-repeat;
        transform: scaleY(-1);
        background-size: 100%;
      }
    }
    .collapse-body {
      display: inherit;
    }
  }
}

.cs-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  padding: 45px 24px 0px 24px;
  z-index: 100;

  .list-scroll {
    min-height: auto;
    max-height: 200px;
    overflow: auto;
  }
  .or {
    padding: 24px;
    text-align: center;
    font-size: 18px;
    color: var(--ion-color-light);
  }

  .input-column {
    background: var(--ion-color-light);
    font-size: 14px;
  }

  .alert-content {
    width: 100%;
    text-align: center;
    margin: 10px 0px;

    p {
      font-size: 14px;
      font-weight: bold;
      color: var(--ion-color-primary);
      line-height: 24px;
    }
    span {
      font-size: 14px;
      display: block;
      line-height: 20px;
      color: var(--ion-color-primary);
    }
  }
}
.transparent-bg-modal {
  --background: rgba(0, 0, 0, 0.9);
}

.list-modal {
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.95);
  padding: 0px 24px 0px 24px;
  z-index: 100;
  .modal-content {
    margin: auto 0;
    padding: 2rem;
    background-color: white;
    color: black;
    border-radius: 5px;
  }
  .list-modal-title {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .list-modal-cbrow {
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
    margin-top: 0.5rem;
    ion-checkbox {
      --size: 16px;
      margin-top: 2px;
    }
  }
  .btn-row {
    display: flex;
    justify-content: center;
    gap: 20%;
  }
}

.revive-text {
  font-size: 16px;
  margin: 0px;
  font-weight: normal;
}
.cs-item {
  font-size: 14px;
}

.star-af {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/images/star.svg") no-repeat;
  background-size: cover;
}

.rating-star {
  ion-icon {
    margin-right: 4px;
    font-size: 20px;
    color: var(--ion-color-light);

    &:last-child {
      margin-right: 0px;
    }
  }

  &.sm-star {
    ion-icon {
      font-size: 11px;
    }
  }

  &.one-star {
    ion-icon {
      &:first-child {
        color: var(--ion-color-tertiary);
        position: relative;

        &:after {
          @extend.star-af;
        }
      }
    }
  }
  &.two-star {
    ion-icon {
      &:nth-child(1),
      &:nth-child(2) {
        color: var(--ion-color-tertiary);
        position: relative;

        &:after {
          @extend.star-af;
        }
      }
    }
  }
  &.three-star {
    ion-icon {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        color: var(--ion-color-tertiary);
        position: relative;

        &:after {
          @extend.star-af;
        }
      }
    }
  }
  &.four-star {
    ion-icon {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        color: var(--ion-color-tertiary);
        position: relative;

        &:after {
          @extend.star-af;
        }
      }
    }
  }
  &.five-star {
    ion-icon {
      &:after {
        @extend.star-af;
      }
    }
  }
}

.tab-bar {
  padding-right: 20px;

  .tab-btn {
    display: flex;
    align-items: center;
    padding: 0px 3px;
    cursor: pointer;

    a {
      width: 100%;
      border-color: white;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      text-align: center;
      padding: 8px;
      color: white;

      &.active {
        background: var(--ion-color-secondary);
        color: black;
      }
    }
  }

  .tab-body {
    border-radius: 6px;
    border: 1px solid var(--ion-color-medium);
    overflow: auto;
    height: 191px;
    background-color: white;
    ion-list {
      ion-item {
        --min-height: 35px;
      }
    }
  }
}
.pin-desc {
  display: flex;
  align-content: center;
  margin: 12px 0px;

  span {
    margin-left: 10px;
    margin-top: 5px;
  }
}

.tap-desc {
  display: flex;
  align-content: center;
  margin: 12px 0px;

  span {
    margin-right: 10px;
    margin-top: 2px;
  }
}

.bookmark-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  transform: translateZ(0);

  &.flat {
    padding: 0px;
    align-items: flex-start;
    margin-top: 30px;

    .restaurants-list ion-list {
      height: inherit;
      min-height: auto;
      max-height: 450px;
    }
  }
}

ion-fab.close-fab {
  position: inherit;
  margin-top: 16px;
}
.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.web-600 {
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  border-radius: 30px;
  background-color: black;

  .head {
    text-align: center;
    position: relative;
    width: 100%;

    img {
      height: 35px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin: 5px 0px;
    }

    span {
      font-size: 16px;
      font-style: italic;
      opacity: 0.7;
      margin-bottom: 16px;
      display: block;
    }
  }

  ion-list {
    height: 180px;

    overflow: auto;
    padding-top: 16px;
    padding-right: 16px;

    ion-avatar {
      background: var(--ion-color-primary);
      border-radius: 0px;
      margin-right: 12px;

      &.add-new {
        background: var(--ion-color-secondary);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 52px;
        width: 52px;
        margin-bottom: 12px;

        ion-icon {
          font-size: 2.5rem;
        }
      }
    }

    input {
      width: 100%;
      height: 35px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid var(--ion-color-medium);
      font-size: 16px;
    }

    &.sm-height {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      height: 170px;
      padding-right: 0px;

      ion-button {
        text-transform: capitalize;
        width: 90px;
        height: 34px;
        font-size: 14px;
        --border-radius: 8px;
        --box-shadow: none;
        margin: 32px auto auto;
      }
    }

    &.flat-list {
      height: auto;
      padding: 0 0 0 10px;
      background: transparent;

      ion-item {
        position: relative;

        ion-label {
          padding-right: 16px;
        }

        .next-item {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          font-size: 1.5rem;
        }

        p {
          font-size: 12px;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 63px;
          width: 100%;
          height: 1px;
          background: var(--ion-color-medium);
          z-index: 1;
        }

        &:last-child {
          &:after {
            height: 0px;
          }
        }
      }
    }
  }

  &.hidden {
    overflow: hidden;
  }
}

.alert-block {
  width: 100%;
  text-align: center;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3px;
  }

  span {
    font-size: 13px;
    letter-spacing: 0.3px;
  }

  ion-icon {
    font-size: 2rem;
  }
}

.collapse {
  .collapse-list {
    color: white;
    cursor: pointer;

    .collapse-header {
      padding: 8px 70px 8px 16px;
      display: grid;
      grid-template-columns: 1fr 120px 30px;
      align-items: center;
      position: relative;

      h3 {
        font-size: 16px;
        line-height: 20px;
        margin: 0px;
      }

      p {
        font-size: 12px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 20px;
        background: url("./assets/images/caret_down.png") no-repeat;
        width: 20px;
        height: 20px;
        background-size: 100%;
      }
    }

    .collapse-content {
      padding: 8px 54px 8px 16px;
      background: var(--ion-color-primary-tint);
      position: relative;
      display: none;

      p {
        margin: 0px;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;

        img {
          opacity: 1 !important;
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }

      ion-icon {
        position: absolute;
        top: 6px;
        right: 16px;
        font-size: 1.6rem;
      }
    }

    &.active {
      .collapse-header {
        &:after {
          content: "";
          background: url("./assets/images/caret_down.png") no-repeat;
          transform: scaleY(-1);
          margin-top: -5px;
          background-size: 100%;
        }
      }
      .collapse-content {
        display: block;
      }
    }
  }
}
ion-textarea {
  &.textarea-height {
    text-align: right;
    textarea {
      height: 100px !important;
    }
  }
}

.alert-checkbox-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-message::-webkit-scrollbar {
  display: block !important;
  width: 1em;
}
.alert-checkbox-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-checkbox-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.fix-searchbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--ion-color-primary);
  .flat-search {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.info-panel.home-info-panel {
  img {
    opacity: 1 !important;
    height: 36px !important;
  }
}

.loading-controller {
  .loading-wrapper {
    background: transparent;
    box-shadow: none;
  }
}

@keyframes spinner {
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.spinner {
  animation: spinner 1s linear infinite alternate;
}

.restaurant-button {
  cursor: pointer;
}

.padding0 {
  padding: 0px !important;
}

// ============================================== MARGIN-LEFT_PROPERTIES
@for $i from 0 through 100 {
  .ml#{$i} {
    margin-left: 1px * $i !important;
  }
}

// ============================================== MARGIN-RIGHT_PROPERTIES
@for $i from 0 through 50 {
  .mr#{$i} {
    margin-right: 1px * $i !important;
  }
}

// ============================================== MARGIN-TOP_PROPERTIES
@for $i from 0 through 50 {
  .mt#{$i} {
    margin-top: 1px * $i !important;
  }
}

// ============================================== MARGIN-BOTTOM_PROPERTIES
@for $i from 0 through 90 {
  .mb#{$i} {
    margin-bottom: 1px * $i !important;
  }
}

// ============================================== PADDING-TOP_PROPERTIES
@for $i from 0 through 60 {
  .pt#{$i} {
    padding-top: 1px * $i !important;
  }
}

// ============================================== PADDING-RIGHT_PROPERTIES
@for $i from 0 through 50 {
  .pr#{$i} {
    padding-right: 1px * $i !important;
  }
}

// ============================================== PADDING_BOTTOM_PROPERTIES
@for $i from 0 through 50 {
  .pb#{$i} {
    padding-bottom: 1px * $i !important;
  }
}

// ============================================== PADDING-LEFT_PROPERTIES

@for $i from 0 through 50 {
  .pl#{$i} {
    padding-left: 1px * $i !important;
  }
}

// ============================================== WIDTH_PROPERTIES
@for $i from 1 through 400 {
  .w#{$i} {
    width: 1px * $i !important;
  }
}

// ============================================== WIDTH_PERSENTENCE_PROPERTIES
@for $i from 1 through 100 {
  .wp#{$i} {
    width: 1% * $i !important;
  }
}

// ============================================== HEIGHT_PROPERTIES
@for $i from 1 through 100 {
  .h#{$i} {
    height: 1px * $i !important;
  }
}

// ============================================== HIGHT_PERSENTENCE_PROPERTIES %
.hp100 {
  height: 100% !important;
}
