body {
  .faq-link {
    cursor: pointer;
  }
  @media screen and (min-width: 850px) {
    .custom-modal {
      .modal-wrapper.sc-ion-modal-md,
      .modal-wrapper.sc-ion-modal-ios {
        width: 300px;
        height: 450px;
        position: fixed;
        top: 0;
        right: 0;
        background: var(--ion-color-dark);
      }
    }

    .my-custom-class {
      .modal-wrapper.sc-ion-modal-md,
      .modal-wrapper.sc-ion-modal-ios {
        width: 100%;
        height: 100%;
      }
    }

    .transparent-bg-modal {
      .modal-wrapper.sc-ion-modal-md,
      .modal-wrapper.sc-ion-modal-ios {
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.9);

        .restaurants-list {
          width: 500px;
        }
      }
    }
  }
}
